import React from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import Form from '../../components/Form'

import { useFormData } from '../../hooks/gravityForms'
import Container from '../../components/Container'

const PageTemplate = ({data}) => {
    const page = data.allWpPage.nodes[0]

    const allFormData = useFormData()

    const handleError = ({values, error, reset}) => {
        //handle error
    }
    
    const handleSuccess = ({values, reset, confirmations}) => {
        //handle success
    }

    return (
        <>
            <SEO />
            <Layout>
                <Container tw="py-8">
                    <Form
                        id={2}
                        allFormData={allFormData}
                        successCallback={handleSuccess}
                        errorCallback={handleError}
                    />
                </Container>
            </Layout>
        </>
    )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        content
      }
    }
  }
`